var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-works"},[_c('list-page',{ref:"listPageRef",attrs:{"inline":"","label-width":"80px","model":_vm.model,"fields":_vm.fields,"columns":_vm.columns,"fetch":_vm.fetch,"initLoad":_vm.initLoad,"headerCellStyle":{ background: '#f9fafc' },"submitter":{
      props: {
        style: {
          display: 'flex',
          justifyContent: 'flex-end'
        }
      }
    }},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"addon",fn:function(){return [_c('div',{staticStyle:{"margin-bottom":"16px"}},[_c('el-upload',{staticStyle:{"display":"inline-block","margin-left":"10px"},attrs:{"headers":_vm.headers,"action":_vm.action,"show-file-list":false,"on-success":_vm.uploadSuccess,"on-progress":_vm.uploadProcess,"on-error":_vm.uploadError}},[_c('el-button',{attrs:{"loading":_vm.uploadLoading}},[_vm._v("批量导入作品")])],1),_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"loading":_vm.downloadLoading},on:{"click":_vm.downloadWorks}},[_vm._v(" 导出作品列表 ")]),_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"loading":_vm.clearExcelCacheLoading},on:{"click":_vm.clearExcelCache}},[_vm._v(" 清除excel导入缓存 ")])],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }